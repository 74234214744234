.card-element-container {
    margin-bottom: 20px;
}

.card-element-label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
}

.CardElement {
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    color: #424770;
    background-color: white;
    transition: border-color 0.15s ease;
}

.CardElement--focus {
    border-color: #007bff;
}

.CardElement--invalid {
    border-color: #dc3545;
}
